/* eslint-disable react/prop-types */
import React from "react";
import { Button, Space, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { FilterDropdownProps } from "antd/lib/table/interface";

interface RecordType {
  key: string;
}

const getColumnSearchProps = (
  dataIndex: any,
  searchInput: any,
  searchText: any,
  setSearchText: any,
  searchedColumn: any,
  setSearchedColumn: any
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => {
    return (
      <div style={{ padding: 8 }}>
        <Input
          className="dark:bg-darkinput"
          ref={(node) => (searchInput = node)}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => {
            try {
              handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                setSearchText,
                setSearchedColumn
              );
            } catch (e) {
              // ignore this
            }
          }}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <div className="flex flex-row w-full">
          <button
            className="flex-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() =>
              handleSearch(
                selectedKeys,
                confirm,
                dataIndex,
                setSearchText,
                setSearchedColumn
              )
            }
          >
            Search
          </button>
          <button
            className="ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-rose-600 hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => {
              clearFilters && clearFilters();
              setSearchText("");
            }}
          >
            Reset
          </button>
        </div>
      </div>
    );
  },
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value: string | number | boolean, record: any): boolean =>
    record[dataIndex]
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
  render: (text?: string) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ) : (
      text
    ),
});

const handleSearch = (
  selectedKeys: any,
  confirm: any,
  dataIndex: any,
  setSearchText: any,
  setSearchedColumn: any
) => {
  try {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  } catch (e) {
    //ignore this
  }
};

export default getColumnSearchProps;
