import jwt from "jsonwebtoken";
import { getPk } from "../services/auth";

export interface IPayload {
  id: string;
  role: string;
  name: string;
}

export function isAuthenticated(): boolean {
  let pk = window.localStorage.getItem("pk");
  let access_token = window.localStorage.getItem("access_token");
  if (!pk || !access_token) {
    return false;
  }

  let data = jwt.decode(access_token);

  if (data) return true;

  return false;
}

export function decodePayload(): IPayload | null {
  let token = localStorage.getItem("access_token");
  if (token === null) {
    return null;
  }
  return jwt.decode(token) as IPayload;
}
