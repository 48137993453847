import request from "../utils/request";

const HOST = "https://api-vrbook.dotworld.tech";

export async function getInstalls() {
	return request(`${HOST}/api/v1/installs`, {
		method: "GET",
	});
}

export async function updateInstall(id: string, status: boolean) {
	return request(`${HOST}/api/v1/installs/${id}`, {
		method: "PATCH",
		data: {
			blocked: status,
		},
	});
}
