import { Button, message, Space, Table } from "antd";
import React, { useEffect } from "react";
import { getInstalls, updateInstall } from "src/services/installs";
import { SearchOutlined } from "@ant-design/icons";
import getColumnSearchProps from "src/utils/useColumnSearch";
import moment from "moment";

function Home(props: any) {
  const [install, setInstalls] = React.useState([]);
  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const resp = await getInstalls();
    if (resp?.status && resp?.data) {
      setInstalls(resp.data);
    } else {
      message.error("Unable to fetch installs");
    }
  };

  const updateStatus = async (id: string, status: boolean) => {
    const resp = await updateInstall(id, status);
    if (resp?.status && resp?.data) {
      message.success("Install updated");
      getList();
    } else {
      message.error("Unable to update install");
    }
  };

  const [searchText, setSearchText] = React.useState("");
  const [searchedColumn, setSearchedColumn] = React.useState(0);
  const refSearchInput = React.useRef();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps(
        "name",
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
      align: "center" as "center",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps(
        "phone",
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
      align: "center" as "center",
    },
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
      align: "center" as "center",
    },
    {
      title: " Type",
      dataIndex: "type",
      key: "type",
      ...getColumnSearchProps(
        "type",
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
      align: "center" as "center",
    },
    {
      title: "Status",
      dataIndex: "blocked",
      key: "blocked",
      align: "center" as "center",
      render: (data: any) => {
        if (data) {
          return <span style={{ color: "red" }}>Blocked</span>;
        } else {
          return <span style={{ color: "green" }}>Active</span>;
        }
      },
    },
    {
      title: "Install Date",
      dataIndex: "created_at",
      key: "install_date",
      align: "center" as "center",
      width: 210,
      render: (date: string) =>
        moment(date).local().format("YYYY-MM-DD HH:mm:ss"),
    },

    {
      title: "Action",
      key: "action",
      align: "center" as "center",
      render: (_: any, record: any) => {
        if (record.blocked) {
          return (
            <Button
              className="rounded-md bg-blue-600"
              type="primary"
              onClick={(e: any) => {
                e.preventDefault();
                updateStatus(record.id, false);
              }}
            >
              Unblock
            </Button>
          );
        } else {
          return (
            <Button
              className="rounded-md"
              danger
              onClick={(e: any) => {
                e.preventDefault();
                updateStatus(record.id, true);
              }}
            >
              Block
            </Button>
          );
        }
      },
    },
  ];

  return (
    <>
      <div className="text-3xl mb-3">Install List</div>{" "}
      <div className="bg-white rounded-md p-3">
        <Table
          dataSource={install}
          columns={columns}
          pagination={{ total: 100, showQuickJumper: true }}
        />
      </div>
    </>
  );
}

export default Home;
