import React, { ReactNode, useEffect, useState, useContext } from "react";
import { Layout, Menu, Button } from "antd";
import { PoweroffOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import {LogoutOutlined} from '@ant-design/icons';

import { AuthContext } from "../contexts/AuthContext";
import { MenuList } from "../App";
import { decodePayload } from "../utils/auth";

import logo from "../assets/logo.png";

import "../styles/misc.css";

const { Header, Content, Sider } = Layout;

interface Props {
	needSider: boolean | true;
	children: ReactNode;
	menuList: MenuList[];
	
}

function SiderDemo(props: Props) {
	let payload = decodePayload();

	const location = useLocation();
	const [selectedKey, setSelectedKey] = useState(
		props.menuList.find((_item) => location.pathname.startsWith(_item.route))
			?.key
	);

	useEffect(() => {
		setSelectedKey(
			props.menuList.find((_item) => location.pathname.startsWith(_item.route))
				?.key
		);
	}, [location]);

	const logOut = () => {
		localStorage.clear();
		window.location.href = "/";
	};

	return (
		<AuthContext.Consumer>
			{({ is_authenticated }) => (
				<Layout>
					{is_authenticated && (
						<Sider width="220" theme="light" trigger={null} breakpoint="sm">
							<img
								className="h-14 mt-4 w-full object-scale-down"
								src={logo}
								alt={"KeyVault"}
							/>

							<Menu
								className={"mt-6 "}
								theme="light"
								mode="inline"
								defaultSelectedKeys={[selectedKey ? selectedKey : "home"]}
							>
								{props.menuList.map((value, index) => (
									<>
										<Menu.Item
											className={"flex items-center place-content-left "}
											key={value.key}
										>
											<Link to={value.route}>
												{_.startCase(_.camelCase(value.key))}
											</Link>
										</Menu.Item>
									</>
								))}
							</Menu>
						</Sider>
					)}
					<Layout className="gradient-bg h-screen overflow-y-auto ">
						{is_authenticated && (
							<Header className="gradient-bg px-8 flex  my-4 justify-end items-end">
								<LogoutOutlined style={{color:'white'}}/>
								<button className=" bg-red-500 text-white rounded-md text-center w-28 h-10 flex items-center justify-center " onClick={logOut}>
									<div className="logOut"><LogoutOutlined style={{color:"white",fontSize:18, marginRight:8}}/></div>LogOut</button>
							</Header>
						)}
						<Content className={`${is_authenticated ? "p-6 " : "p-0"}`}>
							{props.children}
						</Content>
					</Layout>
				</Layout>
			)}
		</AuthContext.Consumer>
	);
}

export default SiderDemo;
