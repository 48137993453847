import React, { ReactNode, useState } from "react";
import { Tabs } from "antd";
import { Formik, Field, Form } from "formik";
import { notification } from "antd";
import Image from "../assets/login.svg";
import { LoginParamsType, loginApi, getPk } from "src/services/auth";

export type LoginProps = {
  children?: ReactNode;
};

const LoginPage: React.FC<LoginProps> = () => {
  const [forgotPass, setForgotPass] = useState<boolean>(false);

  const toggleForgotPass = () => {
    setForgotPass(!forgotPass);
  };

  const login = async (values: LoginParamsType) => {
    let resp = await loginApi(values);
    if (resp?.status && resp?.data) {
      let {
        data: { token },
      } = resp;

      localStorage.setItem("access_token", token);
      let key = await getPk(token);
      localStorage.setItem("pk", key.data);

      window.location.href = "/";
    } else {
      notification.error({
        message: "Unable to login",
        description: "Please make sure you have entered valid credentials",
      });
    }
  };

  const { TabPane } = Tabs;

  function validateEmailLocal(values: LoginParamsType) {
    const errors: any = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    return errors;
  }

  return (
    <div className="flex lg:flex-row md:flex-col h-screen">
      <div className="w-1/2 flex items-center justify-center  bg-gradient-to-b from-blue-500 via-indigo-600 to-purple-700">
        <img className="w-1/2" src={Image} alt="" />
      </div>
      <div className="w-2/3 p-8 flex flex-row justify-center items-center  ">
        {!forgotPass ? (
          <div className="grid grid-cols-1 gap-10 ml-16 md:ml-8 sm:ml-6">
            <div>
              <h2 className="text-left mb-1 text-black font-extrabold text-3xl">
                Get more things done with
                <br /> LogIn platform.
              </h2>
              <h6 className="text-gray-600 font-semibold text-lg">
                Access to the most powerful tool in the
                <br />
                entire design and web industry
              </h6>
              <Tabs defaultActiveKey="1">
                <TabPane tab="LogIn" key="1">
                  <div className="max-w- ">
                    <div className="grid grid-cols-1 gap-8">
                      <Formik
                        initialValues={{
                          email: "",
                          password: "",
                        }}
                        onSubmit={(values: LoginParamsType) => {
                          login(values);
                        }}
                        validate={validateEmailLocal}
                      >
                        {({ errors, touched }) => (
                          <Form>
                            <label
                              htmlFor="email"
                              className="block my-4 p-1 text-black "
                            >
                              <Field
                                className="mt-1 block rounded-md border-gray-300 bg-slate-100 w-80 p-2   "
                                id="email"
                                name="email"
                                type="text"
                                placeholder="E-mail address"
                              />
                              {errors.email && touched.email && (
                                <span className="inline-block mt-1 font-small text-pink-500">
                                  {errors.email}
                                </span>
                              )}
                            </label>
                            <label
                              htmlFor="password"
                              className="block my-4 p-1 text-black"
                            >
                              <Field
                                className="mt-1 block  rounded-md border-gray-300 bg-slate-100 w-80 p-2  "
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Password"
                              />
                              {errors.password && touched.password && (
                                <span className="inline-block mt-1 font-small text-pink-500">
                                  {errors.password}
                                </span>
                              )}
                            </label>

                            <button
                              type="submit"
                              className="btn bg-green-500 active:bg-purple-700 focus:outline-none text-white font-normal mt-6 px-4 py-2 rounded-md"
                            >
                              Login
                            </button>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
            <a
              className="text-purple-500 font-normal hover:underline"
              href=""
              onClick={(e) => {
                e.preventDefault();
                toggleForgotPass();
              }}
            >
              Forgot Password?
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoginPage;
