import React, { ReactNode } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { HomeFilled } from "@ant-design/icons";

import LoginPage from "./pages/Login";
import Home from "./pages/Home";
import Sider from "./components/Sider";

import { isAuthenticated } from "./utils/auth";



export type MenuList = {
  key: string;
  route: string;
  component: any;
  icon?: ReactNode;
};

export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

let menuList: MenuList[] = [
  {
    key: "home",
    route: "/home",
    component: React.lazy(() => import("./pages/Home")),
    icon: <HomeFilled />,
  },
];

export function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (isAuthenticated()) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}


export function LoginRedirect({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (isAuthenticated()) {
    return <Navigate to={{ pathname: authenticationPath }} />;
  } else {
    return outlet ;
  }
}



// const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
//   authenticationPath: "/",
// };

// const AuthWrapper = ({ component: Component }: any) => {
//   return isAuthenticated() ? (
//     <Component />
//   ) : (
//     <Navigate to={{ pathname: "/home" }} />
//   );
// };

// const PrivateRoute = ({ element: Component, ...rest }: PrivateRouteProps) => {
//   if (!Component) return null;

//   return <Route {...rest} element={<AuthWrapper component={Component} />} />;
// };

function App() {
  return (
    <Router>
     
      <Sider menuList={menuList} needSider={false}>
        <Routes>
          <Route path="/" element={<LoginRedirect authenticationPath="/home"  outlet={<LoginPage />} />}></Route>
            <Route
              path="/home"
              element={
                <ProtectedRoute
                  authenticationPath= "/"
                  outlet={<Home />}
                />
              }
            />
          {/* </React.Suspense> */}
        </Routes>
      </Sider>
    </Router>
  );
}
export default App;
