import request from "../utils/request";

const HOST = "https://api-vrbook.dotworld.tech";
export interface LoginParamsType {
	email: string;
	password: string;
}

export async function loginApi({ email, password }: LoginParamsType) {
	return request(`${HOST}/api/v1/auth`, {
		method: "POST",
		data: {
			email,
			password,
		},
	});
}

export function getPk(access_token: string) {
	return request(`${HOST}/api/v1/auth/pk`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${access_token}`,
		},
	});
}

export function sendResetEmailLink() { }
